:root {
  --h1-baloo-font-family: "Baloo-Regular", Helvetica;
  --h1-baloo-font-size: 72px;
  --h1-baloo-font-style: normal;
  --h1-baloo-font-weight: 400;
  --h1-baloo-letter-spacing: 0.72px;
  --h1-baloo-line-height: 120.00000476837158%;
  --inner-shadow: ;
  --variable-collection-dark-identity: rgba(14, 4, 35, 1);
  --variable-collection-light-identity: rgba(234, 236, 239, 1);
}
