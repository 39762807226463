.HOME {
  background-color: #0e0423;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.HOME .div {
  background-color: var(--variable-collection-dark-identity);
  height: 3092px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.HOME .overlap-group {
  height: 1098px;
  left: 0;
  position: absolute;
  top: -212px;
  width: 1440px;
}

.HOME .bg-ellipse {
  height: 886px;
  left: 0;
  position: absolute;
  top: 212px;
  width: 1440px;
}

.HOME .shadow-ellipse {
  background-color: #ffffff;
  border-radius: 212px;
  filter: blur(1000px);
  height: 424px;
  left: 829px;
  position: absolute;
  top: 0;
  width: 424px;
}

.HOME .down {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  left: 661px;
  position: absolute;
  top: 869px;
}

.HOME .img {
  height: 72px;
  position: relative;
  width: 72px;
}

.HOME .scroll-down {
  color: #a091be;
  font-family: "Fixel-DisplayBold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.16px;
  line-height: 19.2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.HOME .headline {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 244px;
  position: absolute;
  top: 438px;
}

.HOME .gain-a-competitive {
  color: var(--variable-collection-light-identity);
  font-family: "Fixel-DisplayBold", Helvetica;
  font-size: 72px;
  font-weight: 700;
  letter-spacing: 0.72px;
  line-height: 86.4px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 951px;
}

.HOME .our-software-for {
  color: #b8aad5;
  font-family: "Fixel-TextRegular", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: 44.8px;
  position: relative;
  text-align: center;
  width: 834px;
}

.HOME .logo {
  height: 52px;
  left: 574px;
  position: absolute;
  top: 283px;
  width: 291px;
}

.HOME .shadow-ellipse-2 {
  background-color: #ffffff;
  border-radius: 137.5px;
  filter: blur(794px);
  height: 275px;
  left: -135px;
  position: absolute;
  top: 1646px;
  width: 275px;
}

.HOME .bg-pattern {
  height: 363px;
  left: 1108px;
  position: absolute;
  top: 2426px;
  width: 332px;
}

.HOME .bg-pattern-2 {
  height: 412px;
  left: 0;
  position: absolute;
  top: 2043px;
  width: 331px;
}

.HOME .copy {
  height: 28px;
  left: 555px;
  position: absolute;
  top: 3024px;
  width: 331px;
}

.HOME .text-wrapper {
  color: #826faa;
  font-family: "Fixel Display-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 29px;
  letter-spacing: 0.2px;
  line-height: normal;
  position: absolute;
  top: 0;
}

.HOME .copyright {
  height: 24px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 24px;
}

.HOME .contacts {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 80px;
  left: 387px;
  position: absolute;
  top: 2327px;
}

.HOME .text {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 56px;
  position: relative;
}

.HOME .start-your-project {
  color: var(--variable-collection-light-identity);
  font-family: "Fixel-DisplayBold", Helvetica;
  font-size: 96px;
  font-weight: 700;
  letter-spacing: 0.96px;
  line-height: 115.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 640px;
}

.HOME .p {
  color: var(--variable-collection-light-identity);
  font-family: "Fixel-TextRegular", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.HOME .div-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.HOME .div-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.HOME .img-2 {
  height: 24px;
  position: relative;
  width: 24px;
}

.HOME .email-sialabs {
  background: transparent;
  border: none;
  color: var(--variable-collection-light-identity);
  font-family: "Fixel-TextRegular", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  margin-top: -1px;
  padding: 0;
  position: relative;
  width: fit-content;
}

.HOME .text-wrapper-2 {
  color: var(--variable-collection-light-identity);
  font-family: "Fixel-TextRegular", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.HOME .about-us {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 40px;
  left: 348px;
  position: absolute;
  top: 1750px;
}

.HOME .tip {
  align-items: center;
  background-color: #1b0c3c;
  border-radius: 20px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 10px 20px;
  position: relative;
}

.HOME .text-wrapper-3 {
  color: var(--variable-collection-light-identity);
  font-family: "Fixel-DisplayLight", Helvetica;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.24px;
  line-height: 28.8px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.HOME .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 745px;
}

.HOME .text-2 {
  align-self: stretch;
  color: var(--variable-collection-light-identity);
  font-family: "Fixel-Bold", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.HOME .span {
  font-weight: 700;
}

.HOME .text-wrapper-4 {
  font-family: "Fixel-Regular", Helvetica;
}

.HOME .services {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  left: 827px;
  position: absolute;
  top: 1241px;
}

.HOME .services-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.HOME .div-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.HOME .img-3 {
  height: 48px;
  position: relative;
  width: 48px;
}

.HOME .text-wrapper-5 {
  color: var(--variable-collection-light-identity);
  font-family: "Fixel-DisplayBold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.HOME .text-wrapper-6 {
  color: var(--variable-collection-light-identity);
  font-family: "Fixel-DisplayBold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.24px;
  line-height: 33.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.HOME .service {
  align-items: flex-start;
  display: inline-flex;
  gap: 40px;
  left: 227px;
  position: absolute;
  top: 1241px;
}

.HOME .warehouse-management {
  color: var(--variable-collection-light-identity);
  font-family: "Fixel-DisplayBold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 235px;
}

.HOME .text-wrapper-7 {
  color: #b9abd6;
  font-family: "Fixel-TextMedium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 28px;
  position: relative;
  width: 286px;
}

.HOME .headline-2 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  left: 495px;
  position: absolute;
  top: 996px;
}

.HOME .our-services-wrapper {
  align-items: center;
  background-color: #1c0c3d;
  border-radius: 20px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 10px 20px;
  position: relative;
}

.HOME .software {
  color: var(--variable-collection-light-identity);
  font-family: "Fixel Display-Bold", Helvetica;
  font-size: 96px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}
