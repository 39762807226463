:root {
  --h1-baloo-font-family: "Baloo-Regular", Helvetica;
  --h1-baloo-font-size: 72px;
  --h1-baloo-font-style: normal;
  --h1-baloo-font-weight: 400;
  --h1-baloo-letter-spacing: .72px;
  --h1-baloo-line-height: 120%;
  --inner-shadow: ;
  --variable-collection-dark-identity: #0e0423;
  --variable-collection-light-identity: #eaecef;
}

.HOME {
  background-color: #0e0423;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.HOME .div {
  background-color: var(--variable-collection-dark-identity);
  width: 1440px;
  height: 3092px;
  position: relative;
  overflow: hidden;
}

.HOME .overlap-group {
  width: 1440px;
  height: 1098px;
  position: absolute;
  top: -212px;
  left: 0;
}

.HOME .bg-ellipse {
  width: 1440px;
  height: 886px;
  position: absolute;
  top: 212px;
  left: 0;
}

.HOME .shadow-ellipse {
  filter: blur(1000px);
  background-color: #fff;
  border-radius: 212px;
  width: 424px;
  height: 424px;
  position: absolute;
  top: 0;
  left: 829px;
}

.HOME .down {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 869px;
  left: 661px;
}

.HOME .img {
  width: 72px;
  height: 72px;
  position: relative;
}

.HOME .scroll-down {
  color: #a091be;
  letter-spacing: .16px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Fixel-DisplayBold, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  position: relative;
}

.HOME .headline {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 438px;
  left: 244px;
}

.HOME .gain-a-competitive {
  color: var(--variable-collection-light-identity);
  letter-spacing: .72px;
  text-align: center;
  width: 951px;
  margin-top: -1px;
  font-family: Fixel-DisplayBold, Helvetica;
  font-size: 72px;
  font-weight: 700;
  line-height: 86.4px;
  position: relative;
}

.HOME .our-software-for {
  color: #b8aad5;
  letter-spacing: .32px;
  text-align: center;
  width: 834px;
  font-family: Fixel-TextRegular, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 44.8px;
  position: relative;
}

.HOME .logo {
  width: 291px;
  height: 52px;
  position: absolute;
  top: 283px;
  left: 574px;
}

.HOME .shadow-ellipse-2 {
  filter: blur(794px);
  background-color: #fff;
  border-radius: 137.5px;
  width: 275px;
  height: 275px;
  position: absolute;
  top: 1646px;
  left: -135px;
}

.HOME .bg-pattern {
  width: 332px;
  height: 363px;
  position: absolute;
  top: 2426px;
  left: 1108px;
}

.HOME .bg-pattern-2 {
  width: 331px;
  height: 412px;
  position: absolute;
  top: 2043px;
  left: 0;
}

.HOME .copy {
  width: 331px;
  height: 28px;
  position: absolute;
  top: 3024px;
  left: 555px;
}

.HOME .text-wrapper {
  color: #826faa;
  letter-spacing: .2px;
  font-family: Fixel Display-Regular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 29px;
}

.HOME .copyright {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 2px;
  left: 0;
}

.HOME .contacts {
  flex-direction: column;
  align-items: center;
  gap: 80px;
  display: inline-flex;
  position: absolute;
  top: 2327px;
  left: 387px;
}

.HOME .text {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 56px;
  display: inline-flex;
  position: relative;
}

.HOME .start-your-project {
  color: var(--variable-collection-light-identity);
  letter-spacing: .96px;
  text-align: center;
  width: 640px;
  margin-top: -1px;
  font-family: Fixel-DisplayBold, Helvetica;
  font-size: 96px;
  font-weight: 700;
  line-height: 115.2px;
  position: relative;
}

.HOME .p {
  color: var(--variable-collection-light-identity);
  letter-spacing: .32px;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Fixel-TextRegular, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.HOME .div-2 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.HOME .div-3 {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.HOME .img-2 {
  width: 24px;
  height: 24px;
  position: relative;
}

.HOME .email-sialabs {
  color: var(--variable-collection-light-identity);
  letter-spacing: .32px;
  background: none;
  border: none;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  padding: 0;
  font-family: Fixel-TextRegular, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.HOME .text-wrapper-2 {
  color: var(--variable-collection-light-identity);
  letter-spacing: .32px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Fixel-TextRegular, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.HOME .about-us {
  flex-direction: column;
  align-items: center;
  gap: 40px;
  display: inline-flex;
  position: absolute;
  top: 1750px;
  left: 348px;
}

.HOME .tip {
  background-color: #1b0c3c;
  border-radius: 20px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  display: inline-flex;
  position: relative;
}

.HOME .text-wrapper-3 {
  color: var(--variable-collection-light-identity);
  letter-spacing: .24px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Fixel-DisplayLight, Helvetica;
  font-size: 24px;
  font-weight: 300;
  line-height: 28.8px;
  position: relative;
}

.HOME .flexcontainer {
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 745px;
  display: flex;
}

.HOME .text-2 {
  color: var(--variable-collection-light-identity);
  letter-spacing: .32px;
  text-align: center;
  align-self: stretch;
  font-family: Fixel-Bold, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.HOME .span {
  font-weight: 700;
}

.HOME .text-wrapper-4 {
  font-family: Fixel-Regular, Helvetica;
}

.HOME .services {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
  display: inline-flex;
  position: absolute;
  top: 1241px;
  left: 827px;
}

.HOME .services-2 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

.HOME .div-4 {
  flex: none;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.HOME .img-3 {
  width: 48px;
  height: 48px;
  position: relative;
}

.HOME .text-wrapper-5 {
  color: var(--variable-collection-light-identity);
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Fixel-DisplayBold, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.HOME .text-wrapper-6 {
  color: var(--variable-collection-light-identity);
  letter-spacing: .24px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Fixel-DisplayBold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  position: relative;
}

.HOME .service {
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
  position: absolute;
  top: 1241px;
  left: 227px;
}

.HOME .warehouse-management {
  color: var(--variable-collection-light-identity);
  letter-spacing: 0;
  width: 235px;
  margin-top: -1px;
  font-family: Fixel-DisplayBold, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.HOME .text-wrapper-7 {
  color: #b9abd6;
  letter-spacing: .2px;
  width: 286px;
  font-family: Fixel-TextMedium, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  position: relative;
}

.HOME .headline-2 {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 996px;
  left: 495px;
}

.HOME .our-services-wrapper {
  background-color: #1c0c3d;
  border-radius: 20px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  display: inline-flex;
  position: relative;
}

.HOME .software {
  color: var(--variable-collection-light-identity);
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Fixel Display-Bold, Helvetica;
  font-size: 96px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

/*# sourceMappingURL=index.8a3de0b4.css.map */
